<template>
  <div class="h-full">
    <Html class="h-full bg-gray-100 dark:bg-gray-800">
      <Head>
        <Title>WeTix Console</Title>
      </Head>
    </Html>

    <Body class="h-full" />
    <NuxtLoadingIndicator />
    <TenantAdminDevBanner v-if="showDevBar" />
    <div v-if="!online" class="bg-red-600 text-white text-right px-2 py-1">
      You are offline. Some features may not work.
    </div>
    <div id="wetix-topbar-wrapper">
      <HeadlessDisclosure as="header" class="bg-white shadow dark:bg-gray-800">
        <div
          class="mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 dark:divide-gray-700 lg:px-8"
        >
          <div class="relative h-16 flex justify-between">
            <div class="relative z-10 px-2 flex lg:px-0">
              <div class="flex-shrink-0 flex items-center">
                <img
                  class="h-8 w-auto"
                  src="~/assets/wetix-logo-text-navy.svg"
                  alt="WeTix"
                >
                <LayoutAppSwitcher active-app-name="Tickets" />
              </div>
            </div>
            <div
              class="hidden relative z-0 flex-1 px-2 sm:flex sm:items-center sm:justify-center md:absolute md:inset-0"
            >
              <UInput
                class="max-w-xs w-full"
                icon="i-heroicons-magnifying-glass-20-solid"
                color="white"
                :trailing="false"
                placeholder="Search (ctrl+K)"
                @click="searchStore.openSearchOverlay()"
              />
            </div>
            <div class="relative z-10 flex items-center lg:hidden gap-1">
              <LayoutQuickAccessMenu />
              <LayoutColorModeButton />
              <!-- Mobile Search Button -->
              <UButton
                class="sm:hidden"
                color="gray"
                variant="ghost"
                icon="i-heroicons-magnifying-glass-20-solid"
                @click="searchStore.openSearchOverlay()"
              />
              <!-- Mobile menu button -->
              <UButton
                color="gray"
                variant="ghost"
                icon="i-heroicons-bars-3-20-solid"
                @click="showMenu = true"
              />
            </div>
            <div
              class="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center gap-1"
            >
              <LayoutQuickAccessMenu />
              <LayoutColorModeButton />
              <client-only>
                <NavTenantSwitcher />
              </client-only>
              <!-- <NavNotificationsButton /> -->
              <!-- Profile dropdown -->
              <LayoutUserProfileMenu />
            </div>
          </div>

          <nav class="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
            <NuxtLink
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              active-class="bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-white"
              class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium text-gray-900 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-white"
            >
              {{ item.name }}
            </NuxtLink>
          </nav>
        </div>
      </HeadlessDisclosure>

      <!-- Hamburger menu for mobile -->
      <LayoutMenuDrawer
        class="lg:hidden"
        :links="navigation"
        :user-navigation="userNavigation"
        :auth-state="authState"
        :show-menu="showMenu"
        @close-menu="showMenu = false"
      />

      <!-- MAIN PAGE SLOT -->
      <div
        id="wetix-dash-main-page-wrapper"
        class="min-h-screen dark:bg-gray-800"
      >
        <div
          v-if="$route.fullPath !== '/app/tenant-admin'"
          class="py-2 px-4 sm:px-8 md:px-10 border-t dark:border-gray-600 bg-white dark:bg-gray-800"
        >
          <LayoutBreadcrumbs />
        </div>
        <!-- <OnboardingCompleteAccountSetupBanner v-if="authState?.isAuthed" /> -->
        <div class="border-t dark:border-gray-600">
          <slot />
        </div>
      </div>

      <!-- FOOTER -->
      <LayoutFooterAppGeneral />
    </div>
    <!-- OVERLAYS / OUT OF VIEW -->
    <client-only>
      <SearchOverlay />
    </client-only>
    <!-- <client-only>
      <OnboardingWizard />
    </client-only> -->
    <NotificationBasic />
    <UNotifications />
  </div>
</template>

<script setup lang="ts">
// Import stores

import { useMagicKeys, invoke, until, useOnline } from '@vueuse/core'
import { useSearchStore } from '~/stores/search'
import { useAuthState } from '~/stores/auth'
import { useGlobalAuthToken } from '~/stores/globalAuthToken'
// import { useGlobalTenantAccountSetup } from "~/stores/tenantAccountSetup";
// import { useNotificationState } from '~/stores/notifications'

// Import useActiveElement from vueUse to activate the global search dialog when search box is clicked
// Use onKeyStroke to launch the command box when ctrl + / is pressed.

// All admin pages should be passed through the authCheck middleware.
// definePageMeta({
//   // Use the auth middleware to check where the user should be redirected to after logging in.
//   // middleware: ["authadminonly"]
// })

// Nuxt app context
const { ssrContext } = useNuxtApp()

// Global auth token
const globalAuthToken = useGlobalAuthToken()

// Auth state
const authState = useAuthState()

const online = useOnline()

// let authHasUser
// if (!ssrContext) { authHasUser = $firebaseAuth?.currentUser }

// Composable to watch ID token and set cookie.
useIDTokenChange()

// On page load, we want to clear the current wetix-token cookie and only render the page once it's present.
const token = useCookie('wetix-token', {
  path: '/'
})

// console.warn(`PAGE LOAD. Token: ${token.value}`)

// If there's no token, it's expired and we should wait until it refreshes.
if (!token.value) {
  // Show the loading overlay
  // Clear the globalAuthToken, because it's reactive so we can wait for it to update.
  globalAuthToken.value = null
  invoke(async () => {
    if (!ssrContext) {
      // let refreshTokenLoaderIndicator = $loading.show()

      await until(authState.isAuthed).toBeTruthy({ timeout: 5000 })
      // refreshTokenLoaderIndicator.hide()
    }
  })
}

// Pinia search store
const searchStore = useSearchStore()

// // Pinia notification store
// const notificationStore = useNotificationState()

// // Config
// const config = useRuntimeConfig()

// Listen for ctrl + / keystroke to launch the command box
const keys = useMagicKeys()
const ctrlForwardslash = keys['Ctrl+K']
watch(ctrlForwardslash, (v) => {
  if (v) {
    console.log('Ctrl + / have been pressed. ')
  }
  if (v && searchStore.showGlobalSearchOverlay) {
    console.log('Closing global search box')
    searchStore.closeSearchOverlay()
  } else if (v && !searchStore.showGlobalSearchOverlay) {
    console.log('Opening global search box')
    searchStore.openSearchOverlay()
  }
})

const showMenu = ref(false)

const showDevBar = useDevModeActive()

const navigation = [
  {
    name: 'Dashboard',
    href: '/app/tenant-admin',
    // icon: HomeIcon,
    lineBreak: true
  },
  {
    name: 'Events',
    href: '/app/tenant-admin/events'
    // icon: ClipboardListIcon
  },
  {
    name: 'Orders',
    href: '/app/tenant-admin/orders'
    // icon: CollectionIcon
  },
  {
    name: 'Customers',
    // icon: UserIcon,
    href: '/app/tenant-admin/customers',
    lineBreak: true
  },
  // {
  //   name: 'Coupons',
  //   href: '/app/tenant-admin/coupons',
  //   lineBreak: true
  // },
  // {
  //   name: "Integrations",
  //   href: "/app/tenant-admin/integrations",
  //   icon: SaveIcon,
  //   current: false,
  // },
  {
    name: 'Scanners',
    href: '/app/tenant-admin/scanners',
    // icon: CogIcon,
    current: false
  },
  {
    name: 'Settings',
    href: '/app/tenant-admin/settings',
    // icon: CogIcon,
    current: false
  },
  {
    name: 'Help',
    href: '/app/tenant-admin/help',
    // icon: InformationCircleIcon,
    current: false
  },
  {
    name: 'More',
    href: '/app/tenant-admin/more/emails',
    // icon: DotsVerticalIcon,
    current: false
  }
]

const userNavigation = [
  { name: 'Profile', href: '/app/user/profile' },
  { name: 'User Portal', href: '/app/user' },
  { name: 'Messages', href: '/app/tenant-admin/messages' }
  // { name: 'Sign out', href: '/app/sign-out' },
]

// Watch for current user and redirect to login if not logged in.
// we don't need this watcher on server
const router = useRouter()
const route = useRoute()
const user = useCurrentUser()
onMounted(() => {
  watch(user, (user, prevUser) => {
    if (prevUser && !user) {
      // user logged out
      router.push('/login')
    } else if (user && typeof route.query.redirect === 'string') {
      // user logged in
      router.push(route.query.redirect)
    }
  })
})
</script>
