<template>
  <div>
    <UDropdown :items="items" mode="click" :ui="{ item: { disabled: 'cursor-text select-text' } }" :popper="{ placement: 'bottom-start' }">
      <UTooltip text="Account menu">
        <UButton size="xs" color="gray" variant="ghost">
          <template #leading>
            <UAvatar
              v-if="profilePhotoIsLoading || error || !authState.profilePhoto"
              icon="i-heroicons-user"
            />
            <UAvatar
              v-if="!profilePhotoIsLoading && !error && authState.profilePhoto"
              :src="authState.profilePhoto"
            />
          </template>
        </UButton>
      </UTooltip>
      <template #account="{ item }">
        <div class="text-left">
          <p>
            Signed in as
          </p>
          <p class="truncate font-medium text-gray-900 dark:text-white">
            {{ authState.email }}
          </p>
        </div>
      </template>
    </UDropdown>
  </div>
</template>

<script setup lang="ts">
import { useImage } from '@vueuse/core'

// Auth state
import { useAuthState } from '~/stores/auth'
const authState = useAuthState()

const { $signOut } = useNuxtApp()

const { isLoading: profilePhotoIsLoading, error } = useImage({ src: authState.profilePhoto })

const items = ref([
  [{
    slot: 'account',
    disabled: true
  }],
  [{
    label: 'Account Settings',
    icon: 'i-heroicons-cog',
    click: () => { navigateTo('/app/user/profile') }
  },
  {
    label: 'Support',
    icon: 'i-heroicons-question-mark-circle',
    click: () => { navigateTo('/app/tenant-admin/help') }
  }],
  [{
    label: 'Sign Out',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: () => { $signOut() }
  }]
])
</script>
