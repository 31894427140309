<template>
  <div>
    <UDropdown :items="items">
      <UTooltip text="Quick access menu">
        <UButton size="xs" color="primary" icon="i-heroicons-plus" :ui="{ rounded: 'rounded-full' }" />
      </UTooltip>
    </UDropdown>
  </div>
</template>

<script setup lang="ts">
const items = ref([
  [{
    label: 'New Order',
    icon: 'i-heroicons-pencil-square-20-solid',
    click: () => { navigateTo('/app/tenant-admin/orders/create') }
  }]
])
</script>
