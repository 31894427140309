<template>
  <UBreadcrumb :links="links" />
</template>

<script setup lang="ts">
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase())
}

const route = useRoute()

const links = computed(() => {
  // Initialize with the home link
  const breadcrumbLinks = [{
    // label: 'Dashboard',
    icon: 'i-heroicons-home',
    to: '/app/tenant-admin'
  }]

  // Split the route path and filter out empty segments
  let paths = route.path.split('/').filter(p => p)

  // Check if the path starts with 'app/tenant-admin' and adjust accordingly
  if (paths.length >= 2 && paths[0] === 'app' && paths[1] === 'tenant-admin') {
    paths = paths.slice(2)
  }

  // Add other links based on the route
  paths.forEach((path, index) => {
    breadcrumbLinks.push({
      label: capitalizeWords(path.replace(/-/g, ' ')),
      to: '/app/tenant-admin/' + paths.slice(0, index + 1).join('/')
    })
  })

  return breadcrumbLinks
})
</script>
